const navBarMenus = {
  menus: [
    {
      id: 1,
      label: "Home",
      url: "/",
    },
    {
      id: 4,
      label: "About",
      url: "/about-us",
    },
    {
      id: 3,
      label: "Services",
      url: "/services",
    },
    {
      id: 4,
      label: "Contact",
      url: "/contact",
    },
  ],
};

export default navBarMenus;
// const navBarMenus = {
//     "menus": [
//         {
//             "id": 1,
//             "label": "Home",
//             "url": "/"
//         },
//         {
//             "id": 2,
//             "label": "Hobbies",
//             "url": "/register"
//         },
//         {
//             "id": 2,
//             "label": "Projects",
//             "url": "/projects"
//         },
//         {
//             "id": 3,
//             "label": "Services",
//             "type": "parent",
//             "subMenus": [
//                 {
//                     "id": 31,
//                     "label": "Web Development",
//                     "url": "/webdev"
//                 },
//                 {
//                     "id": 32,
//                     "label": "Mobile App Development",
//                     "url": "/mobiledev"
//                 },
//                 {
//                     "type": "divider"
//                 },
//                 {
//                     "id": 33,
//                     "label": "DBA",
//                     "url": "/dba"
//                 },
//                 {
//                     "id": 34,
//                     "label": "Quality Assurance",
//                     "url": "/qa"
//                 }
//             ]
//         },
//         {
//             "id": 3,
//             "label": "Contacts",
//             "url": "/contacts"
//         },
//         {
//             "id": 4,
//             "label": "About Us",
//             "url": "/about-us"
//         }
//     ]
// }

// export default navBarMenus;
