import React from "react";
import PropTypes from "prop-types";
import RcButton from "components/ui/rcButton/RcButton";
import { Card } from "reactstrap";

const Buttons = ({ containerCss }) => {
  return (
    <section className={containerCss}>
      <Card className="mb-2" body>
        <h4>BUTTONS*</h4>
        <div>
          <RcButton type="info" onClick={() => {}}>
            {"Silent Sanctuary"}
          </RcButton>
          <RcButton type="success" onClick={() => {}}>
            {"Success"}
          </RcButton>
        </div>
        <RcButton type="danger" onClick={() => {}}>
          {"Danger"}
        </RcButton>
        <RcButton type="warn" onClick={() => {}}>
          {"Warning"}
        </RcButton>
        <RcButton type="primary" onClick={() => {}}>
          {"Primary"}
        </RcButton>
        <RcButton type="secondary" onClick={() => {}}>
          {"Secondary"}
        </RcButton>
        <RcButton type="tertiary" onClick={() => {}}>
          {"Tertiary"}
        </RcButton>
        <RcButton type="outline-primary" onClick={() => {}}>
          {"Outline"}
        </RcButton>
        <RcButton type="outline-secondary" onClick={() => {}}>
          {"Outline Secondary"}
        </RcButton>
        <RcButton type="outline-tertiary" onClick={() => {}}>
          {"Outline Tertiary"}
        </RcButton>
        <RcButton type="outline-black" onClick={() => {}}>
          {"Outline Black"}
        </RcButton>
        <RcButton type="primary" disabled={false} onClick={() => {}}>
          {"Enabled Button"}
        </RcButton>
        <RcButton
          type="primary"
          disabled={true}
          loading={false}
          onClick={() => {}}
        >
          {"Disabled button"}
        </RcButton>

        {/* Set of buttons */}
        <RcButton
          type="primary"
          name="submit"
          targetName="submit"
          loading={true}
          onClick={() => {}}
        >
          {"Primary"}
        </RcButton>
        <RcButton
          type="secondary"
          name="view"
          targetName="submit"
          loading={true}
          onClick={() => {}}
        >
          {"View"}
        </RcButton>
        <RcButton
          type="secondary"
          name="update"
          targetName="submit"
          loading={true}
          onClick={() => {}}
        >
          {"Update"}
        </RcButton>
        <RcButton type="tertiary" name="update" size="lg" onClick={() => {}}>
          Medium (md)
        </RcButton>
        <RcButton type="secondary" name="update" size="lg" onClick={() => {}}>
          Large (lg)
        </RcButton>
        <RcButton
          type="primary"
          name="update"
          size="lg"
          block={true}
          onClick={() => {}}
        >
          Block
        </RcButton>
      </Card>
    </section>
  );
};

Buttons.propTypes = {
  containerCss: PropTypes.string,
};

Buttons.defaultProps = {};

export default Buttons;
