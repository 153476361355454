import { useMediaQuery } from "react-responsive";

function useMediaQueryExt() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const getCurrentMediaBreakpoint = () => {
    if (isMobile) return "mobile";
    if (isTablet) return "tablet";
    if (isDesktop) return "desktop";
  };

  return { getCurrentMediaBreakpoint };
}

export default useMediaQueryExt;
