import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./secondary-text.scss";

function SecondaryText() {
  return (
    <section className="secondary-text-wrapper">
      <h3 className="mt-3 fg-gray-500">
        <span className="me-3">Profile</span>
        <FontAwesomeIcon
          icon="fa-solid fa-square"
          className="separator"
          color="fg-gray-100"
        />
        <span className="ms-3">Linked In</span>
      </h3>
    </section>
  );
}

SecondaryText.propTypes = {};

SecondaryText.defaultProps = {};

export default SecondaryText;
