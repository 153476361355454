import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import RcTable from "components/ui/rcTable/RcTable";
import makeData from "./makeData";
import RcTable2 from "components/ui/rcTable/RcTable2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableDemo = ({ containerCss }) => {
  const columnsDef = useMemo(
    () => [
      // { Header: "First Name", accessor: "firstName" },
      // { Header: "Last Name", accessor: "lastName" },
      // { Header: "Age", accessor: "age" },

      {
        Header: "Personal Info",
        columns: [
          { Header: "First Name", accessor: "firstName" },
          { Header: "Last Name", accessor: "lastName" },
        ],
      },
      {
        Header: "Edad",
        columns: [
          { Header: "Age", accessor: "age" },
          { Header: "Status", accessor: "status" },
          { Header: "Visits", accessor: "visits" },
          { Header: "Progress", accessor: "progress" },
          { Header: "Job Description", accessor: "jobDescription" },
        ],
      },
    ],
    []
  );

  const headerSettings = useMemo(
    () => ({
      bold: true,
    }),
    []
  );

  const data = useMemo(() => makeData(500), []);

  return (
    <Card className="mb-2" body>
      <h4>DATATABLE*</h4>
      <RcTable
        columns={columnsDef}
        data={data}
        headerSettings={headerSettings}
        actionButtons={{
          disableResizing: true,
          width: 220,
          headerName: "Aksyon",
          displayOrder: "last",
          //containerCss: "data-table",
          onActionClick: ({ id, row }) => {
            switch (id) {
              case "defAddButton":
                alert(`Add ${row.age}!!!!!`);
                break;

              case "defEditButton":
                alert(`Edit mo ${row.age}!!!!!`);
                break;

              case "customButton1":
                alert(`Delete ${row.age}`);
                break;

              case "customButton2":
                alert(`Edit ${row.age}`);
                break;

              default:
                alert("otherwise!");
            }
          },
          buttons: [
            {
              id: "defAddButton",
              tooltip: "Add record",
              // onPreRender: ({ id, row }) => {
              //   return row.id === 2 ? { hide: true } : null;
              // },
            },
            {
              id: "defEditButton",
            },
            {
              id: "customButton1",
              icon: <i className="fas fa-trash-alt" />,
              tooltip: "Delete record",
              //buttonCss: "btn-data-table-custom",
              //buttonCss:"bg-red mr-1",
              onPreRender: ({ id, row }) => {
                return row.age === 0 ? { disabled: true } : null;
              },
              // onActionClick: ({ id, row }) => {
              //   alert(`Delete ${row.age}`);
              // },
            },
            {
              id: "customButton2",
              icon: <i className="fas fa-edit" />,
              tooltip: "Edit record",
              onPreRender: ({ id, row }) => {
                return row.age === 23 ? { hide: true } : null;
              },
              // onActionClick: ({ id, row }) => {
              //   alert(`Edit ${row.age}`);
              // },
            },
          ],
        }}
        //showSelectionCheckbox={false}
        //paginationOptions={{ sizeSelection: [5, 15, 20], defaultPageSize: 15 }}
      />
      {/* <RcTable2 /> */}
    </Card>
  );
};

TableDemo.propTypes = {
  containerCss: PropTypes.string,
};

TableDemo.defaultProps = {};

export default TableDemo;
