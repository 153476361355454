import HttpConfiguration from 'core/http/component/HttpConfiguration';

function HttpInterceptorHandler() {
  const handleReLogin = () => {
    alert("Relogin");
  };

  const handleNetworkError = () => {
    alert("Network error");
  };

  const handleForbiddenAccess = () => {
    alert("Forbidden");
  };

  return (
    <HttpConfiguration
      onReLogin={handleReLogin}
      onNetworkError={handleNetworkError}
      onForbiddenAccess={handleForbiddenAccess}
    />
  );
}

export default HttpInterceptorHandler;
