import React, { memo } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import rehypeRaw from "rehype-raw";
import "./mark-down.scss";

const MarkDown = ({ text, escapeHtml, skipHtml, containerCss }) => {
  return (
    <ReactMarkdown
      className={`markdown ${containerCss}`}
      children={text}
	  escapeHtml={escapeHtml}
      skipHtml={skipHtml}
      linkTarget="_blank"
	   rehypePlugins={[rehypeRaw]}
    />
  );
};

MarkDown.propTypes = {
  text: PropTypes.string,
  escapeHtml: PropTypes.bool, 
  skipHtml: PropTypes.bool,
  containerCss: PropTypes.string,
};

MarkDown.defaultProps = {
	escapeHtml: false,
	skipHtml: false,
  containerCss: "mb-2",
};

export default memo(MarkDown);
