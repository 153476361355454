import React from "react";
import { Label, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import "./readonly-fields.scss";

const FreeTextReadOnly = ({ name, label, value, readableViewTextEmptyValue }) => {
  return (
    <div className="readonlyView pt-2 pr-2 pb-0 mb-2">
      <Row>
        <Col>
          <Label style={{ fontWeight: 600 }} htmlFor={name}>
            {label}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-muted">{`${
            value && value !== "" ? value : readableViewTextEmptyValue
          }`}</div>
        </Col>
      </Row>
    </div>
  );
};

FreeTextReadOnly.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  readableViewTextEmptyValue: PropTypes.string,
};

FreeTextReadOnly.defaultProps = {
  readableViewTextEmptyValue: "N/A",
};

export default FreeTextReadOnly;
