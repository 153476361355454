import React, { useState, useRef, useEffect } from "react";
import { noop } from "lodash";
import { Button, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import "./news-letter.scss";
import RcButton from "../rcButton/RcButton";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const NewsLetter = ({
  title,
  description,
  buttonTitle,
  inputPlaceholder,
  containerCss,
  buttonType,
  onClick,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [parent] = useAutoAnimate();

  const handleClick = () => {
    setShowInput(!showInput);
    onClick();
  };

  return (
    <section className={`news-letter ${containerCss}`}>
      {title && <h4>{title}</h4>}

      {description && <small>{description}</small>}

      <div className="d-flex flex-row justify-content-center align-content-center">
        <div ref={parent}>
          {showInput && (
            <div className="me-3">
              <Input
                className="d-none d-xxl-flex"
                placeholder={inputPlaceholder}
              />
            </div>
          )}
        </div>

        <RcButton
          type={buttonType}
          containerCss="d-none d-xxl-flex"
          onClick={handleClick}
        >
          {buttonTitle}
        </RcButton>

        {/* <div className="input-container d-flex d-xxl-none">
          <Input className="me-1" placeholder={inputPlaceholder} />
          <RcButton type={buttonType} onClick={onClick}>
            {buttonTitle}
          </RcButton>
        </div> */}
      </div>
    </section>
  );
};

NewsLetter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  containerCss: PropTypes.string,
  buttonTitle: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  buttonType: PropTypes.string,
  onSubscribe: PropTypes.func,
};

NewsLetter.defaultProps = {
  title: "Sign up for our newsletter",
  description: "Sign up for our mailing list to receive new product alerts.",
  buttonTitle: "Subscribe",
  inputPlaceholder: "Email address",
  buttonType: "info",
  onSubscribe: noop,
};

export default NewsLetter;
