import React from "react";
import PropTypes from "prop-types";
import "./footer-layout2.scss";
import { NavLink } from "react-router-dom";
import SocialLinks from "components/ui/socialLinks/SocialLinks";
import NewsLetter from "components/ui/newsLetter/NewsLetter";

const FooterLayout2 = ({ data }) => {
  const {
    webSiteName,
    webSiteDescription,
    groupLink1,
    groupLink2,
    socialLinks,
  } = data;

  return (
    <div className="row footer-layout">
      <div className="col-md-4 col-12 text-left item mb-2">
        <b className="title">{webSiteName}</b>
        <p className="tsmall2 about-us mt-2">{webSiteDescription}</p>
      </div>
      <div className="col">
        <div className="d-flex flex-row justify-content-start">
          <div className="d-flex flex-column text-left item">
            <b className="title mb-1">{groupLink1.groupName}</b>
            {groupLink1.links.map((o, i) => (
              <NavLink to="/" key={i}>
                {o.label}
              </NavLink>
            ))}
          </div>
          <div className="d-flex flex-column text-left itemx">
            <b className="title mb-1">{groupLink2.groupName}</b>
            {groupLink2.links.map((o, i) => (
              <NavLink to="/" key={i}>
                {o.label}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
      <div className="col-xxl-4 col-12 mt-4">
        <div className="d-flex flex-row justify-content-center justify-content-xxl-end">
          <SocialLinks data={socialLinks} containerCss="social-links" />
        </div>
        <NewsLetter buttonType={"primary"} containerCss="rca-news-letter" />
      </div>
    </div>
  );
};

FooterLayout2.propTypes = { data: PropTypes.object };

FooterLayout2.defaultProps = {};

export default FooterLayout2;
