import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import SplashLoader from "components/features/splashLoader/SplashLoader";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NavigateScrollToTop from "components/ui/navigateScrollToTop/NavigateScrollToTop";

import HomeView from "views/home/homeView";
//import AboutUsView from "./views/about/aboutView";
//import ContactUsView from "./views/contactUs/contactUsView";
import DemoView from "./views/demo/demoView";
import PageNotFoundView from "./views/pages/404/pageNotFoundView";
import FullLayout from "pageLayouts/fullLayout/FullLayout";
import Blanklayout from "pageLayouts/blankLayout/BlankLayout";
import ErrorBoundary from "components/ui/errorBoundary/ErrorBoundary";
import HttpInterceptorHandler from "components/features/httpInterceptorHandler/HttpInterceptorHandler";
import LoginView from "views/auth/login/loginView";
import ProtectedRoute from "components/ui/protectedRoute/ProtectedRoute";
import { Suspense } from "react";
import SuspenseLabel from "components/features/suspenseLabel/SuspenseLabel";

// Font sobrang awesome :)
library.add(fab, far, fas);

const AboutUsView = lazy(() => import("./views/about/aboutView"));
const ContactUsView = lazy(() => import("./views/contactUs/contactUsView"));

const getFullLayoutRoutes = () => {
  return (
    <>
      <Route index element={<HomeView />} />
      <Route path="about" element={<AboutUsView />} />
      <Route path="contacts" element={<ContactUsView />} />
      <Route
        path="demo"
        element={
          <ProtectedRoute>
            <DemoView />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<PageNotFoundView />} />
    </>
  );
};

const getPagesLayoutRoutes = () => {
  return (
    <>
      <Route index element={<h1>Pages - nothing special here...</h1>} />
      <Route path="*" element={<PageNotFoundView />} />
    </>
  );
};

const getAuthLayoutRoutes = () => {
  return (
    <>
      <Route index element={<LoginView />} />
      <Route path="login" element={<LoginView />} />
      <Route path="*" element={<PageNotFoundView />} />
    </>
  );
};

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <HttpInterceptorHandler />
        <SplashLoader>
          <NavigateScrollToTop />
          <Suspense fallback={<SuspenseLabel />}>
            <Routes>
              <Route path="/" element={<FullLayout />}>
                {getFullLayoutRoutes()}
              </Route>
              <Route path="auth" element={<Blanklayout />}>
                {getAuthLayoutRoutes()}
              </Route>
              <Route path="pages" element={<Blanklayout />}>
                {getPagesLayoutRoutes()}
              </Route>
            </Routes>
          </Suspense>
        </SplashLoader>
      </ErrorBoundary>
    </div>
  );
}

export default App;
