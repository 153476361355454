import React from "react";
import Splash from "components/ui/splash/Splash";
import useAuth from "hooks/useAuth";

const SplashLoader = ({ children }) => {
  const { loadProfileAsync } = useAuth();

  const handleDataLoader = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await loadProfileAsync();
        console.log("User Profile loaded...");

        resolve("User Profile loaded.");
      } catch (err) {
        reject(err);
      }
    });
  };

  return <Splash onDataLoad={handleDataLoader}>{children}</Splash>;
};

export default SplashLoader;
