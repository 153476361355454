import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

const ObjectWatch = ({ containerCss, title, value }) => {
  return (
    <section className={containerCss}>
      <Card className="mt-2" body>
        <h4>{title}</h4>
        <pre>
          <code>{JSON.stringify(value, null, 5)}</code>
        </pre>
      </Card>
    </section>
  );
};

ObjectWatch.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  containerCss: PropTypes.string,
};

ObjectWatch.defaultProps = { title: "Object Values" };

export default ObjectWatch;
