import React from "react";
import PropTypes from "prop-types";
import "./mobile-layout.scss";
import SearchBox from "../search/SearchBox";

const MobileLayout1 = ({
  containerCss,
  headerGroups,
  page,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  showSearch,
}) => {
  let targetHeaderGroup = headerGroups.length > 1 ? 1 : 0;

  const renderMobileView = () => {
    let result = page.map((row, i) => {
      return (
        <div className="item-container striped shadow-sm" key={i}>
          {row.cells.map((cell, ci) => (
            <div className="item">
              <div className="fw-600 tsmall1 header-text">
                {headerGroups[targetHeaderGroup].headers[ci].Header}
              </div>
              <div>{cell.render("Cell")}</div>
            </div>
          ))}
        </div>
      );
    });

    return result;
  };

  return (
    <section id="mobileLayout" className={`${containerCss}`}>
      {showSearch && (
        <SearchBox
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {renderMobileView()}
    </section>
  );
};

MobileLayout1.propTypes = {
  containerCss: PropTypes.string,
};

MobileLayout1.defaultProps = {};

export default MobileLayout1;
