import PropTypes from "prop-types";
import AnimatedBgContent from "components/ui/animatedBgContent/AnimatedBgContent";
import "./skill-set.scss";
import SkillItem from "./skillItem/SkillItem";

function SkillSet({ containerCss, imageUrl, skillSetImageUrl }) {
  const getSecondaryText = () => {
    return (
      <span className="text-monospace fg-white">
        {/* Software development is challenging and fun to build. My super friends
        got me covered. */}
        &quot;The great growling engine of change – Technology.&quot; Alvin Toffler
      </span>
    );
  };

  return (
    <AnimatedBgContent
      fxType="brightenAndZoomIn2"
      imageUrl={imageUrl}
      containerCss={`skills-animated-bg-wrapper ${containerCss}`}
      //introContainerCss="d-flex flex-column justify-content-centerx align-content-centerx flex-wrap"
      secondaryComponent={getSecondaryText()}
    >
        <div className="d-flex flex-column justify-content-center align-items-center bg-pinkx w-75 w-lg-100">
          <h1 className="rca-heading2 fg-white w-100 mt-5x">
            Rcnet's Super Friends
          </h1>
          <div className="d-flex flex-row flex-wrap justify-content-center align-items-center my-5 bg-greenx">
            <SkillItem
              imageUrl="/images/skillset/logos/html2.png"
              imageCss="html5"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/react.png"
              imageCss="react"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/dotnetcore.png"
              imageCss="netcore"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/vscode.png"
              imageCss="vscode"
            />
            <SkillItem imageUrl="/images/skillset/logos/javascript.png" />
            <SkillItem
              imageUrl="/images/skillset/logos/azure.png"
              imageCss="azure"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/expo-react.png"
              imageCss="expo"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/csharp.png"
              imageCss="csharp"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/redux.png"
              imageCss="redux"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/redis.png"
              imageCss="redis"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/sql-server.png"
              imageCss="sql"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/react-native.png"
              imageCss="rn"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/angular.png"
              imageCss="angular"
            />
            <SkillItem
              imageUrl="/images/skillset/logos/docker.png"
              imageCss="docker"
            />
          </div>
          {/* <img
          className="skills-image mt-5"
          src={skillSetImageUrl}
          alt="skill set"
        ></img> */}
        </div>
    </AnimatedBgContent>
  );
}

SkillSet.propTypes = {
  containerCss: PropTypes.string,
  imageUrl: PropTypes.string,
  skillSetImageUrl: PropTypes.string,
};

SkillSet.defaultProps = {
  imageUrl: "/images/skillset/bg-skillset-2.jpg",
  skillSetImageUrl: "/images/skillset/skillset.png",
};

export default SkillSet;
