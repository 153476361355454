import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

const PageNumberMobileLayout1 = ({
  pageIndex,
  pageOptions,
  gotoPage,
}) => {
  
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center mb-1">
        <div className="d-flex flex-row justify-content-center align-items-center mx-2">
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
          <div className="pl-1 pr-3" style={{ minWidth: "80px" }}>
            <span>of {pageOptions.length}</span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

PageNumberMobileLayout1.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

PageNumberMobileLayout1.defaultProps = {};

export default PageNumberMobileLayout1;
