import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import "./off-canvas-menus.scss";

function OffCanvasCustomMenus({ containerCss }) {
  return (
    <section className={`off-canvas-custom-menu-wrapper ${containerCss}`}>
      <hr className="fg-white" />
      <div className="fg-white">
        Connect with me{" "}
        <FontAwesomeIcon icon="fa-solid fa-heart" color="pink" />
      </div>
      <div className=" d-flex flex-row ">
        {/* <a
          href="https://www.instagram.com/"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-instagram"
            className="social-icon"
          />
        </a> */}
        <a
          href="https://www.linkedin.com/"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-linkedin"
            className="social-icon"
          />
        </a>
        <a
          href="https://github.com/rcnet4"
          alt="gitHub"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-4"
        >
          <FontAwesomeIcon icon="fa-brands fa-github" className="social-icon" />
        </a>
        <a
          href="https://facebook.com"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-facebook-square"
            className="social-icon"
          />
        </a>
        <a
          href="https://twitter.com"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-twitter me-3"
            className="social-icon"
          />
        </a>
      </div>
    </section>
  );
}

OffCanvasCustomMenus.propTypes = {
  containerCss: PropTypes.string,
};

OffCanvasCustomMenus.defaultProps = {};

export default OffCanvasCustomMenus;
