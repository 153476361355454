import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormInput } from "components/ui/formikFields";
import RcButton from "components/ui/rcButton/RcButton";
import { DemoSchema } from "./validators";
import { Card } from "reactstrap";
import RcBlockUi from "components/ui/blockUi/RcBlockUi";
import FormSelect from "components/ui/formikFields/formSelect/FormSelect";
import FormWatch from "components/ui/formikFields/formWatch/FormWatch";

const selectData = [
  {
    value: "1",
    label: "New",
  },
  { value: "2", label: "Existing" },
];

const FormDemo = ({ containerCss }) => {
  const [loading, setLoading] = useState();
  const [targetLodingName, setTargetLoadingName] = useState();

  const onFormSubmit = (values) => {
    debugger;
    console.log(values);
    setLoading(true);
    setTargetLoadingName("submit");
  };

  const handleReset = () => {
    setLoading(true);
    setTargetLoadingName("review");
  }

  return (
    <section className={containerCss}>
      <Card className="mb-2" body>
        <RcBlockUi blocking={loading}>
          <h4>FORM*</h4>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fname: "",
              lname: "",
              email: "",
              message: "",
              subscribe: true,
              customerType: [],
            }}
            validationSchema={DemoSchema}
            onSubmit={onFormSubmit}
          >
            {({ handleSubmit, isSubmitting, ...rest }) => (
              <>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <FormInput
                      type={"text"}
                      name="fname"
                      showLabel={true}
                      label={"First name"}
                      labelBold={true}
                      showRequiredLabel={true}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormInput
                      type={"text"}
                      name="lname"
                      showLabel={true}
                      label={"Last name"}
                      labelBold={true}
                      showRequiredLabel={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormInput
                      type={"text"}
                      name="email"
                      showLabel={true}
                      label={"Email"}
                      labelBold={true}
                      showRequiredLabel={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormInput
                      type={"textarea"}
                      name="message"
                      showLabel={true}
                      label={"Message"}
                      labelBold={true}
                      showRequiredLabel={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormInput
                      type={"checkbox"}
                      name={"subscribe"}
                      label={"Subscribe to newsletter"}
                      showRequiredLabel={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormSelect
                      name={"customerType"}
                      label={"Customer Type"}
                      showLabel
                      options={selectData}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <RcButton
                      name="submit"
                      targetName={targetLodingName}
                      type="primary"
                      loading={loading}
                      onClick={handleSubmit}
                    >
                      Submit
                    </RcButton>
                    <RcButton
                      name="review"
                      targetName={targetLodingName}
                      type="tertiary"
                      loading={loading}
                      onClick={handleReset}
                    >
                      Reset
                    </RcButton>
                    <RcButton
                      name="test"
                      targetName={targetLodingName}
                      type="tertiary"
                      loading={loading}
                      onClick={handleReset}
                    >
                      Test
                    </RcButton>
                  </div>
                </div>
                <FormWatch />
              </>
            )}
          </Formik>
        </RcBlockUi>
      </Card>
    </section>
  );
};

FormDemo.propTypes = {
  containerCss: PropTypes.string,
};

FormDemo.defaultProps = {};

export default FormDemo;
