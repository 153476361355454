import React from "react";
import PropTypes from "prop-types";
import "./image-button.scss";
import { noop } from "lodash";

function ImageButton({ imageUrl, fxType, containerCss, label, onClick }) {
  const getFxTypeCss = () => {
    switch (fxType) {
      case "default":
        return "img-hover-zoom--default";

      case "quickZoom":
        return "img-hover-zoom--quick-zoom";

      case "pointZoom":
        return "img-hover-zoom--point-zoom";

      case "zoomAndRotate":
        return "img-hover-zoom--zoom-n-rotate";

      case "zoomSlowmo":
        return "img-hover-zoom--slowmo";

      case "brightenAndZoomIn":
        return "img-hover-zoom--brightness";

      case "horizontalZoomAndPan":
        return "img-hover-zoom--zoom-n-pan-h";

      case "verticalZoomAndPan":
        return "img-hover-zoom--zoom-n-pan-v";

      case "blurOutAndZoomIn":
        return "img-hover-zoom--blur";

      case "colorizeZoomIn":
        return "img-hover-zoom--colorize";

      case "brightenAndZoomIn2":
        return "img-hover-zoom--brightness2";

      default:
        return "img-hover-zoom--default";
    }
  };

  return (
    <section className={`image-button ${containerCss}`} onClick={onClick}>
      <div className={`img-hover-zoom box-shadows ${getFxTypeCss()}`}>
        <img src={imageUrl} alt="Button bg content" />
      </div>
      <div
        className={`pe-none d-flex flex-column justify-content-center align-items-center image-label`}
      >
        {label}
      </div>
    </section>
  );
}

ImageButton.propTypes = {
  containerCss: PropTypes.string,
  imageUrl: PropTypes.string,
  label: PropTypes.string,
  secondaryComponent: PropTypes.object,
  fxType: PropTypes.string,
  onClick: PropTypes.func,
};

ImageButton.defaultProps = { fxType: "brightenAndZoomIn2", onClick: noop };

export default ImageButton;
