import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header-right-menus.scss";

function HeaderRightMenus({ containerCss }) {
  return (
    <section
      className={`header-right-menu-wrapper d-flex flex-row ${containerCss}`}
    >
      {/* <a
        href="https://www.instagram.com/"
        alt="instagram"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-instagram"
          className="social-icon"
        />
      </a> */}
      <div className="tsmallxs under-construction d-flex flex-column justify-content-center p-1 mx-3 my-3">
        <div className="px-2">SITE UNDER CONSTRUCTION</div>
      </div>
      <a
        href="https://www.linkedin.com/in/ryancristobal/"
        alt="Rcnet's LinkedIn"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-linkedin"
          className="social-icon"
        />
      </a>
      <a
        href="https://github.com/rcnet4"
        alt="gitHub"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon icon="fa-brands fa-github" className="social-icon" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100087228409975"
        alt="Rcnet's facebook page"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-facebook-square"
          className="social-icon"
        />
      </a>
      <a
        href="https://twitter.com/LordryanRyan"
        alt="instagram"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-3"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-twitter me-3"
          className="social-icon"
        />
      </a>
    </section>
  );
}

HeaderRightMenus.propTypes = {
  containerCss: PropTypes.string,
};

HeaderRightMenus.defaultProps = {};

export default HeaderRightMenus;
