import PropTypes from "prop-types";
import AnimatedBgContent from "components/ui/animatedBgContent/AnimatedBgContent";
import SecondaryText from "./secondaryText/SecondaryText";
import SocialMediaMenus from "../pluggableMenus/socialMediaMenus/SocialMediaMenus";
import "./hero.scss";

function Hero({ introText, imageUrl, containerCss }) {
  return (
    <AnimatedBgContent
      imageUrl={imageUrl}
      containerCss={`home-animated-bg-wrapper ${containerCss}`}
      //introContainerCss="hero-intro-text"
      //introContainerCss="d-flex flex-column justify-content-center align-content-center flex-wrap"
      //secondaryComponent={<SecondaryText />}
      secondaryComponent={<SocialMediaMenus containerCss="social-icons" />}
    >
      <h1 className="rca-heading2 fg-white w-75 intro-text">{introText}</h1>
    </AnimatedBgContent>
  );
}

// rcnet is a fullstack developer who loves to innovate and design modern systems. Architect by heart and a pet lover.

Hero.propTypes = {
  introText: PropTypes.string,
  imageUrl: PropTypes.string,
  containerCss: PropTypes.string,
};

Hero.defaultProps = {
  introText:
    "Rcnet is a Full Stack Engineer that is passionate about innovating and designing modern systems",
  // introText:
  //   "Rcnet is a Fullstack Developer who loves to innovate and to work hands-on",
  imageUrl: "/images/hero/hero-bg.jpg",
};

export default Hero;
