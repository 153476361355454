import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Suspense } from "react";
import SuspenseLabel from "components/features/suspenseLabel/SuspenseLabel";
import FurBabiesVideo from "./FurBabiesVideo";

const Gunpla = React.lazy(() => import("./GunplaBuilding"));
const Coding = React.lazy(() => import("./PassionForCoding"));
//const Furbabies = React.lazy(()=> import("./FurBabiesVideo"));

function ContentFactory({
  selection,
  videoJsOptions,
  showVideo,
  onPlayerReady,
}) {
  if (selection === 1 && showVideo) {
    return (
      <FurBabiesVideo
        videoJsOptions={videoJsOptions}
        onPlayerReady={onPlayerReady}
      />
    );
  }

  if (selection === 2) {
    return (
      <Suspense fallback={<SuspenseLabel />}>
        <Gunpla />
      </Suspense>
    );
  }

  if (selection === 3) {
    return (
      <Suspense fallback={<SuspenseLabel />}>
        <Coding />
      </Suspense>
    );
  }
}

ContentFactory.propTypes = {
  selection: PropTypes.number,
  videoJsOptions: PropTypes.object,
  showVideo: PropTypes.bool,
  onPlayerReady: PropTypes.func,
};

ContentFactory.defaultProps = { onPlayerReady: noop };

export default ContentFactory;
