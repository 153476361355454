import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function RcHelmet({ title, description, keywords, children }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {children}
    </Helmet>
  );
}

RcHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

RcHelmet.defaultProps = {};

export default RcHelmet;
