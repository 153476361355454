import React from "react";
import PropTypes from "prop-types";
import { Button, Card } from "reactstrap";
import { DefaultMediaQuery, Mobile } from "components/ui/responsive";
import PageNumber from "./pageNumber/PageNumber";
import PagingMobileLayoutFactory from "./layouts/PaginationMobileLayoutFactory";

const Pagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  paginationContainerCss,
  ...rest
}) => {
  const renderPagination = () => {
    return (
      <div className="pagination d-flex flex-row justify-content-between">
        <div className="w30 h10 d-flex flex=row justify-content-between">
          <Button
            className="w50 mr-1"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"First"}
          </Button>
          <Button
            className="w50 mr-1"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"Prev"}
          </Button>
        </div>

        <PageNumber {...rest} gotoPage={gotoPage} />

        <div className="w30 h10 d-flex flex=row justify-content-between">
          <Button
            className="w50 mr-1"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next"}
          </Button>
          <Button
            className="w50 mr-1"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {"Last"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <section className={paginationContainerCss}>
      <Card className="py-1 px-1 mb-1">
        <DefaultMediaQuery>{renderPagination()}</DefaultMediaQuery>
        <Mobile>
          <PagingMobileLayoutFactory
            {...rest}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Mobile>
      </Card>
    </section>
  );
};

Pagination.propTypes = {
  paginationContainerCss: PropTypes.string,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

Pagination.defaultProps = {};

export default Pagination;
