import React from "react";
import { FormGroup } from "reactstrap";
import { useFormikContext, useField } from "formik";
import PropTypes from "prop-types";
import InputField from "components/ui/inputField/InputField";
import ValidationMessage from "./../validationMessage/ValidationMessage";
import FreeTextReadOnly from "../readonly/FreeTextReadOnly";

const FormInput = ({ name, readableView, ...rest }) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field] = useField({ name });

  if (readableView)
    return (
      <FreeTextReadOnly {...rest} name={name} label={rest.label} value={field.value} />
    );

  return (
    <FormGroup>
      <InputField
        {...rest}
        name={name}
        value={field.value}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <ValidationMessage name={name} />
    </FormGroup>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  inputTip: PropTypes.string,
  placeholder: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  readableView: PropTypes.bool,
  readableViewTextEmptyValue: PropTypes.string,
  onChange: PropTypes.func,
};

FormInput.defaultProps = {
  showRequiredLabel: false,
  labelBold: false,
  readableView: false,
  onChange: () => {},
};

export default FormInput;
