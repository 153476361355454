import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

const PageNumber = ({
  pageIndex,
  pageOptions,
  pageSize,
  paginationOptions,
  setPageSize,
  gotoPage,
}) => {
  const options = {
    sizeSelection:
      paginationOptions?.sizeSelection === undefined
        ? [10, 20, 30, 40, 50]
        : paginationOptions.sizeSelection,
    selectionLabel:
      paginationOptions?.selectionLabel === undefined
        ? "Show @pageSize"
        : paginationOptions.selectionLabel,
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-row justify-content-center align-items-center mb-1">
        <div className="w-25 mr-1">
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </div>
        <div className="bg-redx pl-1 pr-3">
          <span>of {pageOptions.length}</span>{" "}
        </div>
        <Input
          type="select"
          name="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="w-50"
        >
          {options.sizeSelection.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {options.selectionLabel.replace("@pageSize", pageSize)}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

PageNumber.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

PageNumber.defaultProps = {};

export default PageNumber;
