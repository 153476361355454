import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./about.scss";
import { ContentPane } from "components/ui/contentPane";
import RcButton from "components/ui/rcButton/RcButton";

function About2({ photoUrl, containerCss }) {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSection(true);
    }, 500);
  }, []);

  return (
    <ContentPane
      isFullWidth={true}
      hasShadow={false}
      containerCss={`about2-section-wrapper mb-5 ${containerCss}`}
    >
      <div className="photo d-flex flex-column justify-content-center align-content-center flex-wrap">
        <img src={photoUrl} alt="about" className="img-style" />
        {/* d-flex flex-row justify-content-end align-items-center */}
        <div className="about--content">
          {showSection && (
            <div className="about--info-section d-flex flex-column justify-content-center align-items-center">
              <h2 className="rca-heading2">&lt;about/&gt;</h2>
              <p>
                An experienced Fullstack Engineer / Architect by heart based in
                Laguna, PH, who loves working on web, cross-platform app
                development and other programmable projects. He is currently
                working for an Australian company that he visited several times.
              </p>
              <p>
                His coding journey began when he created a shell application
                menu for MS-DOS programs using batch file scripting. He is
                driven by a passion for learning and creativity, and he strives
                to improve and achieve his goals.
              </p>
              <RcButton type="outline-rounded-primary">Tell me more</RcButton>
              {/* <RcButton type="outline-rounded-special">Tell me more</RcButton> */}
            </div>
          )}
        </div>
      </div>
    </ContentPane>
  );
}

About2.propTypes = {
  photoUrl: PropTypes.string,
  containerCss: PropTypes.string,
};

About2.defaultProps = { photoUrl: "images/about/about2.jpg" };

export default About2;
