import React from "react";
import PropTypes from "prop-types";
import { useAsyncDebounce } from "react-table";
import { noop } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";

const SearchBox = ({
  globalFilter,
  setGlobalFilter,
  searchLabel,
  showLabel,
  searchPlaceholder,
  searchBoxContainerCss,
}) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleOnChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <section className={`d-flex flex-row align-items-center ${searchBoxContainerCss}`}>
      <FontAwesomeIcon
        icon={["fas", "search"]}
        //color={o.color}
        size={"1x"}
        className="mr-2"
      />
      <div className="align-content-stretch" style={{ width: "100%" }}>
        {showLabel && <span className="mr-2">{searchLabel}</span>}
        <Input
          value={value || ""}
          onChange={handleOnChange}
          placeholder={searchPlaceholder}
        />
      </div>
    </section>
  );
};

SearchBox.propTypes = {
  searchBoxContainerCss: PropTypes.string,
  globalFilter: PropTypes.string,
  searchLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setGlobalFilter: PropTypes.func,
};

SearchBox.defaultProps = {
  searchLabel: "Search:",
  searchPlaceholder: "Search text here...",
  showLabel: false,
  setGlobalFilter: noop,
};

export default SearchBox;
