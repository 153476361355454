/**
 * Component enhancements to support Formik field
 */
import React from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import Select from "components/ui/select/Select";
import ValidationMessage from "components/ui/formikFields/validationMessage/ValidationMessage";
import SelectFieldReadOnly from "../readonly/SelectFieldReadOnly";
import FreeTextReadOnly from "./../readonly/FreeTextReadOnly";

const FormSelect = ({
  name,
  label,
  showLabel,
  showRequiredLabel,
  labelBold,
  readableView,
  onChangeOverride,
  ...rest
}) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field] = useField({ name });

  const handleChange = (name, value) => {
    if (onChangeOverride) onChangeOverride(name, value);
    else setFieldValue(name, value);
  };

  if (readableView && rest.isMulti)
    return (
      <SelectFieldReadOnly
        name={name}
        label={label}
        value={field.value !== "" ? field.value : []}
      />
    );
  else if (readableView && !rest.isMulti)
    return (
      <FreeTextReadOnly
        name={name}
        label={label}
        value={field.value !== "" ? field.value[0].value : ""}
      />
    );

  return (
    <FormGroup>
      <Label className={labelBold ? "fw-600" : ""}>
        {showLabel && <span>{label}</span>}
        {showRequiredLabel && <span className="text-danger ml-1">*</span>}
      </Label>
      <Select
        {...rest}
        value={field.value}
        onChange={(value) => {
          handleChange(name, value);
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <ValidationMessage name={name} />
    </FormGroup>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  readableView: PropTypes.bool,
  onChangeOverride: PropTypes.func,
};

FormSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
  showLabel: true,
  showRequiredLabel: false,
  labelBold: false,
  allowSelectAll: false,
  readableView: false,
};

export default FormSelect;
