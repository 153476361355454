import React from "react";
import { Label, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import "./readonly-fields.scss";

const SelectFieldReadOnly = ({ name, label, value }) => {
  return (
    <div className="readonlyView">
      <div id="multiSelect" className="pt-2 pr-2 pb-0 mb-2">
        <div className="pb-0">
          <Row>
            <Col>
              <Label style={{ fontWeight: 600 }} htmlFor={name}>
                {label}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                {value.map((o, index) => (
                  <li key={index} className="text-muted">
                    {o.value}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SelectFieldReadOnly.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
};

export default SelectFieldReadOnly;
