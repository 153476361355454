import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { ContentPane } from "components/ui/contentPane";
import ImageButton from "components/ui/imageButton/ImageButton";
import "./tell-me-more.scss";
import ContentFactory from "./components/ContentFactory";

function TellMeMore({ containerCss, onClick }) {
  const playerRef = useRef(null);
  const currentlyPlayingRef = useRef(false);
  const [selection, setSelection] = useState(1);
  const [showVideo, setShowVideo] = useState(false);

  const videoJsOptions = {
    autoplay: false,
    controls: false,
    responsive: true,
    muted: true,
    loop: true,
    //fluid: true,
    fill: true,
    sources: [
      {
        src: "videos/chewy-and-bochik.mp4",
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    document.removeEventListener("scroll", scrollFn);
    document.addEventListener("scroll", scrollFn.bind(this), { passive: true });

    return () => {
      document.removeEventListener("scroll", scrollFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollFn = () => {
    console.log(window.scrollY);
    console.log(currentlyPlayingRef.current);
    if (
      window.scrollY > 500 &&
      window.scrollY < 800 &&
      !currentlyPlayingRef.current
    ) {
      setShowVideo(true);
    } else if (
      window.scrollY > 1300 &&
      window.scrollY < 1400 &&
      !currentlyPlayingRef.current
    ) {
      if (selection === 1) {
        console.log("Video playing...");
        playVideo();
      }
    }
  };

  const handleOnClick = (e) => {
    if (e === 1) {
      setSelection(1);
      setTimeout(() => {
        playVideo();
      }, 500);
      return;
    }

    setSelection(e);
    stopVideo();
  };

  const playVideo = () => {
    playerRef.current.play();
    currentlyPlayingRef.current = true;
  };

  const stopVideo = () => {
    playerRef.current.pause();
    playerRef.current.currentTime = 0;
    currentlyPlayingRef.current = false;
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <ContentPane
      isFullWidth={false}
      hasShadow={false}
      containerCss={`tell-me-more-wrapper mb-5  bg-white ${containerCss}`}
    >
      <Row>
        <Col xs={12} md={12} lg={5} xxl={5} className="bg-cyanx">
          <div className="d-flex flex-column justify-content-center align-items-center px-5 mb-5">
            <ImageButton
              imageUrl="/images/about/dogs2.gif"
              label="FUR BABIES & COMPANY"
              fxType="horizontalZoomAndPan"
              containerCss="mb-5"
              onClick={() => handleOnClick(1)}
            />

            <ImageButton
              imageUrl="/images/about/gundam2.gif"
              label="GUNPLA HOBBY"
              fxType="horizontalZoomAndPan"
              containerCss="mb-5"
              onClick={() => handleOnClick(2)}
            />

            <ImageButton
              imageUrl="/images/about/matrix.gif"
              label="PASSION FOR CODING"
              fxType="horizontalZoomAndPan"
              onClick={() => handleOnClick(3)}
            />
          </div>
        </Col>
        <Col xs={12} md={12} lg={6} xxl={6} className="video-container">
          <ContentFactory
            selection={selection}
            videoJsOptions={videoJsOptions}
            showVideo={showVideo}
            onPlayerReady={handlePlayerReady}
          />
        </Col>
      </Row>
    </ContentPane>
  );
}

TellMeMore.propTypes = {
  containerCss: PropTypes.string,
};

TellMeMore.defaultProps = {};

export default TellMeMore;
