import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./about.scss";
import { ContentPane } from "components/ui/contentPane";
import IconButton from "../customButtons/iconButton/IconButton";

function About({ photoUrl, containerCss }) {
  return (
    <ContentPane
      isFullWidth={true}
      hasShadow={false}
      containerCss={`about-section-wrapper ${containerCss}`}
    >
      <div className="about-section d-flex flex-column">
        <div className="about-section--main d-flex flex-column flex-lg-row">
          <div className="rcnet-copyright">STARFOX - 1997</div>
          <div className="about-title d-flex flex-column justify-content-center align-content-center flex-wrap">
            <span className="rca-heading1 custom-heading">about*</span>
          </div>
          <div className="about-content d-flex flex-column justify-content-center align-content-center flex-wrap">
            <p>
              Fullstack Engineer / Architect by heart based in Laguna, PH - with
              an emphasis on the web, cross platform app development or anything
              programmable. Currently, an independent Fullstack developer in an
              Australian based company to which he has travelled twice.
            </p>
            <p>
              His early days in coding began when he tried to create a shell
              application menu for executable programs on MS-DOS using batch
              file scripting.
            </p>
            <p>
              Motivated by a passion for learning and creativity, he is
              constantly improving and working hard to attain his goals.
            </p>
            {/* Fullstack Developer / Architect by heart based in Laguna PH - with a
            focus on web, cross platform mobile app or anything programmable.
            Currently an all around freelance Fullstack Developer at an
            Australian based company. He travelled Australia twice for a
            business trip purposes. His early days in coding started when he
            tries to create a shell app menus for executable programs in MS-DOS
            using a batch file programming. Driven by passion to learn and
            creativity he continously improved himself and work hard. */}
            <div className="ms-auto">
              <IconButton
                icon="fa-solid fa-arrow-right"
                url="http://rcnetlabs.com"
                alt="more"
              />
            </div>
          </div>
        </div>
        <div className="about-section--photo d-flex flex-column flex-lg-row">
          <div className="photo d-flex flex-column justify-content-center align-content-center flex-wrap">
            <img src={photoUrl} alt="about" />
          </div>
          <div className="rcnet-text d-flex flex-column justify-content-center align-content-center flex-wrap">
            <h1 className="rca-regular-heading1 custom-heading2">
              {"{rcnet}"}
            </h1>
          </div>
        </div>
      </div>
    </ContentPane>
  );
}
// <div className={`img-hover-zoom img-hover-zoom--quick-zoomx`}>
//             <img src="images/about/about-pic.jpg" alt="about" />
//           </div>
About.propTypes = {
  photoUrl: PropTypes.string,
  containerCss: PropTypes.string,
};

About.defaultProps = { photoUrl: "images/about/about-pic2.jpg" };

export default About;
