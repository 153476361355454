import React, { createContext, useState } from "react";
import { NavHeaderTypes } from "constants/navHeaderTypes";

export const NavHeaderContext = createContext();

const NavHeaderContextProvider = ({ children }) => {
  const [currentMode, setCurrentMode] = useState(NavHeaderTypes.DUAL_MODE);

  const changeMode = (type) => {
    setCurrentMode(type);
  };

  return (
    <NavHeaderContext.Provider value={{ currentMode, changeMode }}>
      {children}
    </NavHeaderContext.Provider>
  );
};

export default NavHeaderContextProvider;
