/**
 * Application wide settings
 */

const Config = {
  appVersion: "0.1.0",
  apiUrl: "http://localhost:55488/api",
  clientId: "yxcE8bTtKJHHbjdxRQ6X5XKccCetjF",
  authStorageKey: "rca:auth.key",
  content: {
    allowFx: true, // Note, you need to enable $enableTransitionGroupFx in _variables.scss to take effect
  },
  footer: {
    showFatFooter: true,
    fatFooterType: 3,
    copyrightText:
      "© @year@ <a href='https://rcnetlabs.com' target='_blank'>Rcnet Labs</a>. All Rights Reserved | <a href='https://rcnetlabs.com' target='_blank'>Privacy</a> | <a href='https://rcnetlabs.com' target='_blank'>Terms of use</a>",
  },
  gmap: {
    gmapKey: "AIzaSyDkKPfRg4S0EAEeym7z5pQ_MvuA_wCIk2E",
    centerLat: 14.353802118225053,
    centerLng: 121.04748475388396,
    markerLat: 14.353802118225053,
    markerLng: 121.04748475388396,
  },
  components: {
    blockUi: {
      color: "#82ccdd",
    },
  },
  features: {},
};

export default Config;
