/**
 * Utility service for object related
 */
 const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };
  
  const jsonSerialize = (data) => {
    return data !== "" ? JSON.stringify(data) : "";
  };
  
  const shallowEqualObjects = (objA, objB, excludeProps = []) => {
    if (objA === objB) {
      return true;
    }
  
    if (!objA || !objB) {
      return false;
    }
  
    var aKeys = Object.keys(objA);
    var bKeys = Object.keys(objB);
    var len = aKeys.length;
  
    if (bKeys.length !== len) {
      return false;
    }
  
    for (var i = 0; i < len; i++) {
      var key = aKeys[i];
  
      // Exclude props from checking
      if (excludeProps.length > 0) {
        if (excludeProps.includes(key)) continue;
      }
  
      if (objA[key] !== objB[key]) {
        return false;
      }
    }
  
    return true;
  };
  
  export { deepClone, jsonSerialize, shallowEqualObjects };
  