import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as footerData from "data/footer.json";
import navBarMenus from "data/navbar-menus";
import Footer from "components/features/footer/Footer";
import LoginNavButton from "components/features/loginNavButton/LoginNavButton";
import NavigationBar from "components/features/navigationBar/NavigationBar";
import Config from "config";
import RcScrollToTop from "components/ui/rcScrollToTop/RcScrollToTop";
import NavigationBarOffCanvas from "components/features/navigationBar/NavigationBarOffCanvas";
import HeaderRightMenus from "components/features/pluggableMenus/headerRightMenus/HeaderRightMenus";
import OffCanvasCustomMenus from "components/features/pluggableMenus/offCanvasCustomMenus/OffCanvasCustomMenus";
import NavHeaderContextProvider from "components/features/navigationHeader/context/NavHeaderContextProvider";
import NavigationHeader from "components/features/navigationHeader/NavigationHeader";

const FullLayout = () => {
  const location = useLocation();

  return (
    <NavHeaderContextProvider>
      {/* <NavigationBar
        menus={navBarMenus.menus}
        navBarRightPane={<LoginNavButton />}
      /> */}
      <NavigationBarOffCanvas
        menus={navBarMenus.menus}
        navBarRightPane={<LoginNavButton />}
        customComponent={<OffCanvasCustomMenus />}
        menuAlignment="end"
      />
      {/* <NavigationHeader
        menuOptionRight={<HeaderRightMenus />}
        offCanvasCustomComponent={<OffCanvasCustomMenus />}
      /> */}

      <section className="content-wrapper">
        {Config.content.allowFx ? (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={{ enter: 250, exit: 250 }}
              classNames={"fade"}
            >
              <>
                <section className="route-section">
                  <main role="main">
                    <Outlet />
                  </main>
                </section>
              </>
            </CSSTransition>
          </TransitionGroup>
        ) : (
          <section className="route-section">
            <main role="main">
              <Outlet />
            </main>
          </section>
        )}
      </section>
      <Footer
        type={Config.footer.fatFooterType}
        copyrightText={Config.footer.copyrightText}
        data={footerData.default}
        showFatFooter={Config.footer.showFatFooter}
      />
      <RcScrollToTop />
    </NavHeaderContextProvider>
  );
};

export default FullLayout;
