import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./social-media-menus.scss";

function SocialMediaMenus({ containerCss }) {
  return (
    <section
      className={`social-media-menu-wrapper d-flex flex-row justify-content-center align-items-center ${containerCss}`}
    >
      <a
        href="https://www.linkedin.com/in/ryancristobal/"
        alt="Rcnet's LinkedIn"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon icon="fa-brands fa-linkedin" className="social-icon" />
      </a>
      <a
        href="https://github.com/rcnet4"
        alt="gitHub"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon icon="fa-brands fa-github" className="social-icon" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100087228409975"
        alt="Rcnet's facebook page"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-4"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-facebook-square"
          className="social-icon"
        />
      </a>
      <a
        href="https://twitter.com/LordryanRyan"
        alt="instagram"
        target="_blank"
        rel="noreferrer"
        className="menu-options me-3"
      >
        <FontAwesomeIcon
          icon="fa-brands fa-twitter me-3"
          className="social-icon"
        />
      </a>
    </section>
  );
}

SocialMediaMenus.propTypes = {
  containerCss: PropTypes.string,
};

SocialMediaMenus.defaultProps = {};

export default SocialMediaMenus;
