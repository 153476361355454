import React from "react";
import Buttons from "components/features/demo/buttons/Buttons";
import Typography from "components/features/demo/typography/Typography";
import ContentPane from "components/ui/contentPane/ContentPane";
import FormDemo from "components/features/demo/forms/FormDemo";
import TableDemo from "components/features/demo/datatable/TableDemo";
import "./demo-view.scss";

const DemoView = () => {
  return (
    <ContentPane>
      <TableDemo />
      <Typography />
      <Buttons />
      <FormDemo />
    </ContentPane>
  );
};

export default DemoView;
