import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Loader from "react-loaders";
import "loaders.css/loaders.min.css";

const Splash = ({ children, color, onDataLoad }) => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    onDataLoad().finally(() => setShowSplash(false));
  }, []);
  
  return (
    <>
      {showSplash ? (
        <div className="rca-splash">
          <div className="app-splash-container">
            <img
              src="/images/logo/logo-icon.png"
              className="app-splash-logo mb-2"
              alt="logo"
            />
            {/* <Loader active type={"ball-pulse-sync"} color={color} /> */}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

Splash.propTypes = { color: PropTypes.string, onDataLoad: PropTypes.func };

Splash.defaultProps = { color: "#d7d8d1", onDataLoad: noop };

export default Splash;
