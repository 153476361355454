/**
 * UserContextProvider exposes authenticated User props
 * - firstName
 * - lastName
 * - isAuthenticated
 * - login() method
 */
import React, { createContext, useMemo, useReducer } from "react";
import { initialState, miniReducer } from "./store";
import AuthService from "../../services/authService";
import Auth from "./../../core/auth/auth";
import Config from "config";
import AuthorizationService from "../../services/authorizationService";
import ProfileService from "./../../services/profileService";
import { sanitizeAuthorizationData } from "./helper";
import { useNavigate } from "react-router-dom";
import CurrentUser from "core/auth/currentUser";

// User context
export const UserContext = createContext(initialState);

// User Context Provider
export default function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(miniReducer, initialState);
  const currentUser = CurrentUser();

  const navigate = useNavigate();

  /**
   * Login
   * @param {*} payload
   */
  const loginAsync = async (payload) => {
    try {
      let authPayload = {
        userName: payload.userName,
        password: payload.password,
        clientId: Config.clientId,
        grantType: "password",
      };

      let result = await AuthService().login(authPayload);

      const { access_token, refresh_token, expires_in } = result.data;

      debugger;
      Auth.setToken(access_token, refresh_token, expires_in);

      await loadProfileAsync(true);

      navigate("/");
    } catch (err) {
      if (err.response?.status === 400) {
        if (err.response?.data["Client Id"]) {
          dispatch({
            type: "LOGIN_ERROR",
            payload: "Client not found.",
          });
        } else {
          dispatch({
            type: "LOGIN_ERROR",
            payload: err.response?.data["invalid_grant"][0],
          });
        }
        return;
      }

      if (err.response?.status === 404) {
        dispatch({
          type: "LOGIN_ERROR",
          payload: "Oopss server is down, please try again later.",
        });

        return;
      }

      dispatch({
        type: "LOGIN_ERROR",
        payload: "Server error, authentication failed.",
      });
    }
  };

  /**
   * Logout
   */
  const logout = async () => {
    Auth.removeToken();
    dispatch({ type: "LOGOUT_USER" });
    navigate("/login", { replace: true });
  };

  /**
   * Load user profile and initial permissions
   */
  const loadProfileAsync = async (calledByLogin = false) => {
    try {
      let isAuthenticated = currentUser.isAuthenticated();

      if (!isAuthenticated) return;

      dispatch({ type: "LOAD_PROFILE_REQUEST" });

      let resp = await Promise.all([
        currentUser.getUserInfo(),
        ProfileService().getProfileAsync(),
      ]);

      let userInfo = resp[0];

      let profileData = resp[1].data;

      let sanitizeAuthorizationdData = sanitizeAuthorizationData(
        profileData.authorizationData
      );

      profileData.authorizationData = sanitizeAuthorizationdData;

      dispatch({
        type: "LOAD_PROFILE_SUCCESS",
        payload: {
          ...userInfo,
          profileData,
        },
      });
    } catch (err) {
      Auth.removeToken();

      if (!calledByLogin) return;

      if (err.response?.status === 404) {
        dispatch({
          type: "LOAD_PROFILE_FAILURE",
          payload: "Account not found",
        });
        return;
      }

      dispatch({
        type: "LOAD_PROFILE_FAILURE",
        payload: "Unable to load profile",
      });
    }
  };

  /**
   * Load authorization data
   */
  const loadAuthorizationDataAsync = async () => {
    try {
      let authData = await AuthorizationService().getAuthorizationDataAsync();

      dispatch({
        type: "LOAD_AUTH_DATA",
        payload: { authData },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "LOAD_AUTH_FAILURE",
        payload: "Unable to load authorization data",
      });
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      loginAsync,
      logout,
      loadProfileAsync,
      loadAuthorizationDataAsync,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
