import React from "react";
import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";
import { noop } from "lodash";
import { shallowEqualObjects } from "core/utils/objectUtils";
import "./input-field.scss";
import MarkDown from "../markDown/MarkDown";

const InputField = (props) => {
  const {
    label,
    type,
    value,
    showLabel,
    inputTip,
    placeholder,
    textAreaSize,
    showRequiredLabel,
    labelBold,
    errorCss,
    checkBoxCss,
    checkBoxAsLink,
    checkBoxUrlLink,
    checkboxLabelHasLink,
    onChange,
    ...rest
  } = props;
  const handleChange = ({ target }) =>
    onChange(type === "checkbox" ? target.checked : target.value);

  const getCheckboxLabel = () => {
    if (checkBoxAsLink) {
      return (
        <a href={checkBoxUrlLink} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    }

    if (checkboxLabelHasLink) {
      return <MarkDown text={label} />;
    }

    return label;
  };

  const getInputComponent = () => {
    switch (type) {
      case "checkbox":
        return (
          <div>
            <Input
              {...rest}
              id={rest.name}
              style={{
                height: "15px",
                width: "15px",
                position: "inherit",
                marginLeft: 0,
              }}
              className={`ml-2 ${checkBoxCss}`}
              type={type}
              checked={value}
              placeholder={placeholder}
              onChange={handleChange}
            />
            {/* <Label htmlFor={rest.name} className="d-inline-block mt-1x">
            </Label> */}
            {showLabel && (
              <Label
                className={
                  labelBold ? "fw-600 text-indent-label" : "text-indent-label"
                }
                htmlFor={rest.name}
              >
                {getCheckboxLabel()}
              </Label>
            )}
            {showRequiredLabel && <span className="text-danger ml-1x">*</span>}
          </div>
        );

      case "textarea":
        return (
          <div>
            {showLabel && (
              <Label
                className={labelBold ? "fw- wt-field-label" : "wt-field-label"}
                htmlFor={rest.name}
              >
                {label}
              </Label>
            )}
            {showRequiredLabel && <span className="text-danger ml-1">*</span>}
            {inputTip && <p className="tsmall1">{inputTip}</p>}
            <div>
              <Input
                {...rest}
                id={rest.name}
                style={{ height: textAreaSize }}
                type={type}
                value={value}
                placeholder={placeholder}
                className={`${errorCss}`}
                onChange={handleChange}
              />
            </div>
          </div>
        );

      default:
        return (
          <div>
            {showLabel && (
              <Label
                className={
                  labelBold ? "fw-600 wt-field-label" : "wt-field-label"
                }
                htmlFor={rest.name}
              >
                {label}
              </Label>
            )}
            {showRequiredLabel && <span className="text-danger ml-1">*</span>}
            {inputTip && <p className="tsmall1">{inputTip}</p>}
            <div>
              <Input
                {...rest}
                id={rest.name}
                type={type}
                value={value}
                placeholder={placeholder}
                className={`${errorCss}`}
                onChange={handleChange}
              />
            </div>
          </div>
        );
    }
  };

  return <div className="wt-input-field">{getInputComponent()}</div>;
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  inputTip: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaSize: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  errorCss: PropTypes.string,
  checkBoxAsLink: PropTypes.bool,
  checkBoxUrlLink: PropTypes.string,
  checkboxLabelHasLink: PropTypes.bool,
  checkBoxCss: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

InputField.defaultProps = {
  showRequiredLabel: false,
  showLabel: true,
  textAreaSize: "150px",
  labelBold: false,
  checkBoxUrlLink: "/",
  checkboxLabelHasLink: false,
  checkBoxCss: "with-font",
  onChange: noop,
  onBlur: noop,
};

// Custom comparison function
const comparisonFn = function (prevProps, nextProps) {
  let isEqual = shallowEqualObjects(prevProps.value, nextProps.value);
  if (isEqual) isEqual = prevProps.errorCss === nextProps.errorCss;

  return isEqual;
};

export default React.memo(InputField, comparisonFn);
