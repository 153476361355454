import React from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import InputField from "components/ui/inputField/InputField";
import ValidationMessage from "./../validationMessage/ValidationMessage";
import FreeTextReadOnly from "../readonly/FreeTextReadOnly";
import { noop } from "lodash";
import useFastField from "../hooks/useFastField";
import { getStyles } from "../helpers/helpers";

const FormFieldInput = ({
  name,
  validate,
  readableView,
  allowFieldErrorBorder,
  onInputConvertValue,
  onOutputConvertValue,
  ...rest
}) => {
  const { field, meta, helpers } = useFastField({ name, validate });
  if (readableView)
    return (
      <FreeTextReadOnly
        {...rest}
        name={name}
        label={rest.label}
        value={field.value}
      />
    );

  const handleChange = (value) => {
    let finalValue = value;
    if (rest.type === "checkbox") finalValue = onOutputConvertValue(value);
    helpers.setValue(finalValue);
  };

  const convertInputValue = (value) => {
    if (rest.type === "checkbox") return onInputConvertValue(value) ?? value;
    return value;
  };

  let finalInputValue = convertInputValue(field.value);

  return (
    <>
      {rest.type === "checkbox" ? (
        <>
          <InputField
            {...rest}
            {...field}
            errorCss={getStyles(allowFieldErrorBorder, meta)}
            value={finalInputValue}
            name={name}
            onChange={handleChange}
          />
          <ValidationMessage name={name} />
        </>
      ) : (
        <FormGroup>
          <InputField
            {...rest}
            {...field}
            errorCss={getStyles(allowFieldErrorBorder, meta)}
            value={finalInputValue}
            name={name}
            onChange={handleChange}
          />
          <ValidationMessage name={name} />
        </FormGroup>
      )}
    </>
  );
};

FormFieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  inputTip: PropTypes.string,
  placeholder: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  readableView: PropTypes.bool,
  readableViewTextEmptyValue: PropTypes.string,
  allowFieldErrorBorder: PropTypes.bool,
  checkBoxAsLink: PropTypes.bool,
  checkBoxUrlLink: PropTypes.string,
  onOutputConvertValue: PropTypes.func,
  onInputConvertValue: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.func,
};

FormFieldInput.defaultProps = {
  showRequiredLabel: false,
  labelBold: false,
  readableView: false,
  allowFieldErrorBorder: true,
  onChange: noop,
  validate: noop,
  onOutputConvertValue: noop,
  onInputConvertValue: noop,
};

export default FormFieldInput;
