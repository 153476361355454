import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import ObjectWatch from "components/ui/objectWatch/ObjectWatch";

const FormWatch = ({ containerCss, showErrors, showValues }) => {
  const { errors, values } = useFormikContext();

  return (
    <section className={containerCss}>
      {showValues && <ObjectWatch title={"Values"} value={values} />}
      {showErrors && <ObjectWatch title={"Errors"} value={errors} />}
    </section>
  );
};

FormWatch.propTypes = {
  showErrors: PropTypes.bool,
  showValues: PropTypes.bool,
  containerCss: PropTypes.string,
};

FormWatch.defaultProps = { showValues: true, showErrors: true };

export default FormWatch;
