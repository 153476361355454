/**
 * loaderType: "ball-pulse-sync", "ball-beat"
 */
import React from "react";
import PropTypes from "prop-types";
import BlockUi from '@availity/block-ui';
import { Loader } from "react-loaders";
import "loaders.css/loaders.min.css";
import Config from "config";

const RcBlockUi = ({
  blocking,
  lowerZIndex,
  children,
  color,
  loaderType,
  ...rest
}) => (
  <BlockUi
    tag="div"
    blocking={blocking}
    style={{ zIndex: lowerZIndex ? 5 : 1010 }}
    {...rest}
    loader={<Loader active type={loaderType} color={color} />}
  >
    {children}
  </BlockUi>
);

RcBlockUi.propTypes = {
  color: PropTypes.string,
  lowerZIndex: PropTypes.bool,
  loaderType: PropTypes.string,
};

RcBlockUi.defaultProps = {
  color: Config.components.blockUi.color,
  lowerZIndex: true,
  loaderType: "ball-pulse-sync", //ball-beat
};

export default RcBlockUi;
