import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./navigation-brand-off-canvas.scss";
import useMediaQueryExt from "hooks/useMediaQueryExt";

const NavigationBarOffCanvas = ({
  menus,
  companyLink,
  brandLogo,
  offCanvasBrandLogo,
  navBarBrandText,
  brandLogoAlternateText,
  fixed,
  navBarRightPane,
  showBrand,
  menuAlignment,
  brandContainerCss,
  containerCss,
  customComponent,
  contentStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //const { getCurrentMediaBreakpoint } = useMediaQueryExt();

  const toggle = () => setIsOpen(!isOpen);

  const menuAlignmentClass =
    menuAlignment === "start"
      ? "me-auto"
      : menuAlignment === "end"
      ? "ms-auto"
      : "m-auto";

  const renderMenu = (menu, i) => {
    if (!menu.type) {
      return (
        <NavItem key={i}>
          <NavLink
            to={menu.url}
            className={({ isActive }) =>
              isActive ? "nav-link navbar-active" : "nav-link"
            }
          >
            {menu.label}
          </NavLink>
        </NavItem>
      );
    }

    return (
      <UncontrolledDropdown key={i} nav inNavbar>
        <DropdownToggle nav caret>
          {menu.label}
        </DropdownToggle>
        <DropdownMenu end>
          {menu.subMenus.map((o, indx) => (
            <div key={indx}>
              {o.type === "divider" ? (
                <DropdownItem divider />
              ) : (
                <DropdownItem tag="a" href={o.url}>
                  {o.label}
                </DropdownItem>
              )}
            </div>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  //let isDesktop = getCurrentMediaBreakpoint() === "desktop";

  return (
    <div className={`rca-nav-bar nav-off-canvas-wrapper`}>
      <Navbar
        //light
        dark
        expand="lg"
        className={`navbar-bg-color ${containerCss}`}
        container="fluid"
        fixed={fixed}
      >
        {showBrand && (
          <>
            {navBarBrandText ? (
              <div
                className={`navbar-brand-text-container ms-3 ms-xl-3 mx-xxl-0  ${brandContainerCss}`}
              >
                <NavbarBrand href={companyLink} target="_blank">
                  {navBarBrandText}
                </NavbarBrand>
              </div>
            ) : (
              <>
                {/* Desktop */}
                <NavbarBrand
                  href={companyLink}
                  target="_blank"
                  className={`${brandContainerCss} d-none d-lg-flex`}
                >
                  <img
                    src={brandLogo}
                    alt={brandLogoAlternateText}
                    className="navbar-brand-logo"
                  />
                </NavbarBrand>

                {/* Lg and below */}
                <div className="d-flex d-lg-none flex-row justify-content-center w-100 logo">
                  <NavbarBrand
                    href={companyLink}
                    target="_blank"
                    className={brandContainerCss}
                  >
                    <img
                      src={brandLogo}
                      alt={brandLogoAlternateText}
                      className="navbar-brand-logo"
                    />
                  </NavbarBrand>
                </div>
              </>
            )}
          </>
        )}

        <NavbarToggler onClick={toggle} />

        <Nav className={`${menuAlignmentClass} d-none d-lg-flex`} navbar>
          {menus.map((o, i) => renderMenu(o, i))}
        </Nav>

        <Offcanvas isOpen={isOpen}>
          <OffcanvasHeader toggle={toggle} className="bg-white">
            {navBarBrandText ? (
              <div className="off-canvas-header-text">{navBarBrandText}</div>
            ) : (
              <img
                src={offCanvasBrandLogo}
                alt={brandLogoAlternateText}
                className="off-canvas-logo"
              />
            )}
          </OffcanvasHeader>
          <OffcanvasBody>
            <Nav className="ms-auto" navbar>
              {menus.map((o, i) => renderMenu(o, i))}
            </Nav>
            {customComponent}
          </OffcanvasBody>
        </Offcanvas>
      </Navbar>
    </div>
  );
};

NavigationBarOffCanvas.propTypes = {
  menus: PropTypes.array,
  companyLink: PropTypes.string,
  navBarBrandText: PropTypes.string,
  brandLogo: PropTypes.string,
  brandLogoAlternateText: PropTypes.string,
  offCanvasBrandLogo: PropTypes.string,
  showBrand: PropTypes.bool,
  navBarRightPane: PropTypes.object,
  fixed: PropTypes.string,
  menuAlignment: PropTypes.string,
  brandContainerCss: PropTypes.string,
  customComponent: PropTypes.object,
  containerCss: PropTypes.string,
  contentStyle: PropTypes.object,
};

NavigationBarOffCanvas.defaultProps = {
  menus: [],
  companyLink: "https://rcnetlabs.com",
  brandLogo: "/images/logo/logo-site.png",
  brandLogoAlternateText: "Rcnet Labs Logo",
  offCanvasBrandLogo: "/images/logo/logo-site.png", //"/images/logo/rcnet-logo-white.png",
  showBrand: true,
  fixed: "top",
  menuAlignment: "end",
  //brandContainerCss: "me-5",
};

export default NavigationBarOffCanvas;
