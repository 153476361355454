import React from "react";
import PropTypes from "prop-types";

function SuspenseLabel({ label, containerCss }) {
  return (
    <section
      className={`${containerCss} d-flex flex-row justify-content-center align-items-center w-100`}
    >
      <div className="tsmall fg-gray-800">{label}</div>
    </section>
  );
}

SuspenseLabel.propTypes = {
  label: PropTypes.string,
  containerCss: PropTypes.string,
};

SuspenseLabel.defaultProps = {
  label: "Loading...",
};

export default SuspenseLabel;
