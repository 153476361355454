import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

const Typography = ({ containerCss }) => {
  return (
    <section className={containerCss}>
      <Card className="mb-2" body>
        <h4>TYPOGRAPHY*</h4>
        <h2 className="rca-heading2">Welcome to Rcnet App!</h2>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <p className="rca-heading5" style={{ color: "#f6b93b" }}>
          Contact Us
        </p>
        <b>Bold Text</b>
      </Card>
    </section>
  );
};

Typography.propTypes = {
  containerCss: PropTypes.string,
};

Typography.defaultProps = {};

export default Typography;
