import React from "react";
import PropTypes from "prop-types";
import "./animated-bg.scss";

function AnimatedBgContent({
  imageUrl,
  fxType,
  containerCss,
  introContainerCss,
  children,
  secondaryComponent,
}) {
  const getFxTypeCss = () => {
    switch (fxType) {
      case "default":
        return "img-hover-zoom--default";

      case "quickZoom":
        return "img-hover-zoom--quick-zoom";

      case "pointZoom":
        return "img-hover-zoom--point-zoom";

      case "zoomAndRotate":
        return "img-hover-zoom--zoom-n-rotate";

      case "zoomSlowmo":
        return "img-hover-zoom--slowmo";

      case "brightenAndZoomIn":
        return "img-hover-zoom--brightness";

      case "horizontalZoomAndPan":
        return "img-hover-zoom--zoom-n-pan-h";

      case "verticalZoomAndPan":
        return "img-hover-zoom--zoom-n-pan-v";

      case "blurOutAndZoomIn":
        return "img-hover-zoom--blur";

      case "colorizeZoomIn":
        return "img-hover-zoom--colorize";

      case "brightenAndZoomIn2":
        return "img-hover-zoom--brightness2";

      default:
        return "img-hover-zoom--default";
    }
  };

  return (
    <section className={`animated-bg-wrapper ${containerCss}`}>
      <div className={`img-hover-zoom ${getFxTypeCss()}`}>
        <img src={imageUrl} alt="Bg content" />
      </div>
      <div
        className={`intro-hero pe-none d-flex flex-column justify-content-center align-items-center ${introContainerCss}`}
      >
        {children}
        {secondaryComponent && (
          <div className="secondary-component">{secondaryComponent}</div>
        )}
      </div>
    </section>
  );
}

AnimatedBgContent.propTypes = {
  containerCss: PropTypes.string,
  introContainerCss: PropTypes.string,
  imageUrl: PropTypes.string,
  children: PropTypes.object,
  secondaryComponent: PropTypes.object,
  fxType: PropTypes.string,
};

AnimatedBgContent.defaultProps = { fxType: "brightenAndZoomIn2" };

export default AnimatedBgContent;
