/**
 * Action Button Hooks
 * 
 * Sample configuration settings:
 * {
   disableResizing: true,
   minWidth: 80,
   maxWidth: 180,
   headerName: "Aksyon",
   displayOrder: "first or last",
   containerCss: "",
   onActionClick:  ({id,row}) => { }
   buttons: [
     {
	   id: "defAddButton",
	   tooltip: "Add record",
	   onPreRender:({id, row}) => { return row.id === 2 ? {hide: true, disabled: true } },
	   onActionClick: ({id, row}) => { }
	 },
	 {
	   id: "customButton1",
	   icon: "<i className='fas fa-add' />"
	   tooltip: "Add record",
	   buttonCss: ""
	   onPreRender:({id, row}) => { return row.id === 2 ? {hide: true, disabled: true } },
	   onActionClick: ({id,row}) => { }
	 }
   ]
   
}
 */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { noop } from "lodash";
import { getDefaultButton } from "./actionButtonFactory";

const customActionButtonHooks = (actionButtonSettings) => {
  return (hooks) => {
    debugger;
    if (actionButtonSettings == null) return;

    if (
      actionButtonSettings.buttons == null ||
      !Array.isArray(actionButtonSettings.buttons)
    )
      return;

    hooks.visibleColumns.push((columns) => [
      ...buildSettings(columns, actionButtonSettings),
    ]);

    let actionButtonHeaderIndex =
      actionButtonSettings.displayOrder === "first"
        ? 0
        : hooks.visibleColumns.length - 1;

    hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
      // fix the parent group of the selection button to not be resizable
      const selectionGroupHeader =
        headerGroups[0].headers[actionButtonHeaderIndex];
      selectionGroupHeader.canResize = false;
    });
  };
};

const buildGeneralActionButtonSettings = (settings) => {
    debugger;
  return {
    id: "action",
    disableResizing: settings?.disableResizing ?? false,
    width: settings?.width ?? 120,
    //minWidth: settings?.minWidth ?? 80,
    maxWidth: settings?.maxWidth ?? 280,
    Header: settings?.headerName ?? "Actions",
  };
};

const buildSettings = (columns, actionButtonSettings) => {
  if (actionButtonSettings.displayOrder === "first") {
    return [
      {
        ...buildGeneralActionButtonSettings(actionButtonSettings),
        Cell: ({ row }) => (
          <div
            className={`${
              actionButtonSettings?.containerCss ?? "rca-data-table"
            }`}
          >
            {buildActionButtons(row, actionButtonSettings)}
          </div>
        ),
      },
      ...columns,
    ];
  }

  return [
    ...columns,
    {
      ...buildGeneralActionButtonSettings(actionButtonSettings),
      Cell: ({ row }) => (
        <div
          className={`${
            actionButtonSettings?.containerCss ?? "rca-data-table"
          }`}
        >
          {buildActionButtons(row, actionButtonSettings)}
        </div>
      ),
    },
  ];
};

const buildActionButtons = (row, buttonSettings) => {
  let renderButtons = buttonSettings.buttons.map((btn, i) => {
    let clickPayload = { id: btn.id, row: { ...row.original } };
    let preRenderOptions = (btn.onPreRender || noop)(clickPayload);
    let disabled = false;
    let clickHandler =
      btn.onActionClick || buttonSettings.onActionClick || noop;

    // Check component factory
    let newButton = getDefaultButton(
      btn,
      clickPayload,
      clickHandler,
      disabled,
      i
    );
    if (newButton) return newButton;

    if (preRenderOptions && Object.keys(preRenderOptions).length > 0) {
      if (preRenderOptions?.hide ?? false) return null;
      else if (preRenderOptions?.disabled ?? false) disabled = true;
    }

    return (
      <Button
        key={i}
        className={`${btn?.buttonCss ?? "mr-1"}`}
        onClick={() => clickHandler(clickPayload)}
        disabled={disabled}
      >
        {btn.icon}
      </Button>
    );
  });

  return renderButtons;
};

export { customActionButtonHooks };
