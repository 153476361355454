import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import "./rc-scroll-to-top.scss";

const RcScrollToTop = ({iconCss}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", scrollFn);

    return () => {
      //console.log("cleanup!");
      document.removeEventListener("scroll", scrollFn);
    };
  }, []);

  const scrollFn = (e) => {
    toggleVisibility();
  };

  const toggleVisibility = () => {
    //console.log(window.pageYOffset);
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="rc-scroll-to-top">
      {isVisible && (
        <div onClick={() => scrollToTop()}>
          <FontAwesomeIcon
            //icon={["fas", "arrow-circle-up"]}
            icon={["fas", "fa-caret-up"]}
            //color={o.color}
            size={"3x"}
            className={iconCss}
          />
        </div>
      )}
    </section>
  );
};

RcScrollToTop.propTypes = {
  iconCss: PropTypes.string
 };

 RcScrollToTop.defaultProps = {
  iconCss: "rca-scroll"
 };
 

export default RcScrollToTop;
