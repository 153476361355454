import React from "react";
import PropTypes from "prop-types";
import VideoJS from "components/ui/videoPlayer/VideoPlayer";

function FurBabiesVideo({ videoJsOptions, onPlayerReady, containerCss }) {
  return (
    <section className={containerCss}>
      <h3 className="mb-3 rca-heading2 text-center">Fur Babies & Co.</h3>
      <div className="video-wrapper  d-flex flex-column justify-content-start box-shadowsx">
        <VideoJS options={videoJsOptions} onReady={onPlayerReady} />
      </div>
    </section>
  );
}

FurBabiesVideo.propTypes = {
  containerCss: PropTypes.string,
  videoJsOptions: PropTypes.object,
  onPlayerReady: PropTypes.func,
};

FurBabiesVideo.defaultProps = {};

export default FurBabiesVideo;
