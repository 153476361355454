import React from "react";
import PropTypes from "prop-types";

function SkillItem({ containerCss, imageUrl, imageStyle, imageCss }) {
  return (
    <section className={containerCss}>
      <img
        className={`skill-item-image ${imageCss}`}
        style={imageStyle}
        src={imageUrl}
        alt="skill set"
      ></img>
    </section>
  );
}

SkillItem.propTypes = {
  containerCss: PropTypes.string,
};

SkillItem.defaultProps = {};

export default SkillItem;
