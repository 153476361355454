/**
 * Http library
 * rcnet@rcnetlabs
 */

import axios from "axios";
import Config from "./../../config";
import axiosInheritExt from "./axiosInheritExt";
import { HTTP_TIMEOUT } from "./constants";

const Http = () => {
  axiosInheritExt(axios);
  let http = axios.create({ baseURL: Config.apiUrl });
  http.defaults.timeout = HTTP_TIMEOUT;

  console.log(`Http Url: ${Config.apiUrl}`);

  // ===============
  // Methods
  // ===============

  /**
   *
   * @param {resourcePathName} Resource path name for example 'user'
   * @param {cfg} you can use params - An object parameter { ID: 12345 }
   */
  const get = (resourcePathName, cfg = null) =>
    cfg ? http.get(resourcePathName, cfg) : http.get(resourcePathName);

  const post = (resourcePathName, payload, cfg = null) =>
    cfg
      ? http.post(resourcePathName, payload, cfg)
      : http.post(resourcePathName, payload);

  const del = (urlParams, cfg = null) =>
    cfg ? http.delete(urlParams, cfg) : http.delete(urlParams);

  const put = (urlParams, payload, cfg = null) =>
    cfg ? http.put(urlParams, payload, cfg) : http.put(urlParams, payload);

  const patch = (resourcePathName, payload) =>
    http.patch(resourcePathName, payload);

  // Note: Has an issue with interceptor
  const setAuthToken = (token) => {
    http.defaults.headers.common["Authorization"] = token;
    return this;
  };

  // Set Http Global Timeout
  const setHttpTimeout = (timeoutInMs) => {
    http.defaults.timeout = timeoutInMs;
    return this;
  };

  /**
   * Either form, json etc.
   * @param {type} 'form', 'json'
   * Note: if not in the list of types it will apply as custom
   */
  const setContentType = (type) => {
    // Pre-defined list of content types
    let contentType = getContentTypeString(type);
    http.defaults.headers.post["Content-Type"] = contentType;
    return this;
  };

  /**
   *
   * @param {type} Basic content types are supported as of the moment.
   * Try to contribute by adding new types to enhance the library. thanks :P
   * types: form, json, pdf, xml, zip, csv, plain, html
   */
  const getContentTypeString = (type) => {
    let contentType =
      type === "form"
        ? "application/x-www-form-urlencoded"
        : type === "json"
        ? "application/json"
        : type === "pdf"
        ? "application/pdf"
        : type === "xml"
        ? "application/xml"
        : type === "zip"
        ? "application/zip"
        : type === "csv"
        ? "text/csv"
        : type === "plain"
        ? "text/plain"
        : type === "html"
        ? "text/html"
        : type === "jpg"
        ? "image/jpeg"
        : type === "png"
        ? "image/png"
        : type;
    return contentType;
  };

  const getProvider = () => http;

  return {
    get,
    post,
    put,
    delete: del,
    patch,
    getProvider,
    getContentTypeString,
    setContentType,
    setAuthToken,
    setHttpTimeout,
  };
};

export default Http;
