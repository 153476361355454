import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import "./icon-button.scss";

function IconButton({
  url,
  alt,
  isTargetBlank,
  icon,
  iconCss,
  hoverClass,
  onButtonClick,
}) {
  if (onButtonClick) {
    return (
      <a
        href={url}
        alt={alt}
        target={`${isTargetBlank ? "_blank" : ""}`}
        rel="noreferrer"
        className={`icon-button-wrapper ${hoverClass ? hoverClass : null}`}
      >
        <FontAwesomeIcon icon={icon} className={`font-icon ${iconCss}`} />
      </a>
    );
  }

  return (
    <a
      href={url}
      alt={alt}
      target={`${isTargetBlank ? "_blank" : ""}`}
      rel="noreferrer"
      className={`icon-button-wrapper ${hoverClass ? hoverClass : null}`}
    >
      <FontAwesomeIcon icon={icon} className={`font-icon ${iconCss}`} />
    </a>
  );
}

IconButton.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  isTargetBlank: PropTypes.bool,
  icon: PropTypes.string,
  iconCss: PropTypes.string,
  hoverClass: PropTypes.string,
  onButtonClick: PropTypes.func,
};

IconButton.defaultProps = { isTargetBlank: true };

export default IconButton;
