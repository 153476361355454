import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useMediaQueryExt from "hooks/useMediaQueryExt";
import useStateWithRef from "hooks/useStateWithRef";
import "./navigation-header.scss";
import NavigationBarOffCanvas from "components/features/navigationBar/NavigationBarOffCanvas";
import navBarMenus from "data/navbar-menus";
import { NavHeaderTypes } from "constants/navHeaderTypes";
import { NavHeaderContext } from "components/features/navigationHeader/context/NavHeaderContextProvider";

const NavigationHeader = ({
  logoUrl,
  brandNavigateUrl,
  menuOptionRight,
  offCanvasCustomComponent,
  containerCss,
}) => {
  const navRef = useRef();
  const { stateValue, stateRefValue, setStateValue } = useStateWithRef();
  const { getCurrentMediaBreakpoint } = useMediaQueryExt();
  const stateRefValue2 = useRef();
  const { currentMode } = useContext(NavHeaderContext);

  useEffect(() => {
    // Fixed mode (narrow header)
    if (isFixed()) {
      setStateValue({
        containerClass: "rca-sticky-inview",
        headerClass: "header-sticky-inview",
      });
      return;
    }

    // Dual mode
    if (!isFixed()) {
      setStateValue({
        containerClass: "rca-sticky-inview",
        headerClass: "",
      });
    }

    // We call remove first to remove previous registration of scroll event to avoid memory leak
    !isFixed() && document.removeEventListener("scroll", scrollFn);
    !isFixed() &&
      document.addEventListener("scroll", scrollFn, { passive: true });

    return () => {
      !isFixed() && document.removeEventListener("scroll", scrollFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode]);

  const scrollFn = (e) => {
    //let elemHeight = navRef.current.clientHeight;
    //if (window.scrollY > elemHeight + 10  ) { //|| window.scrollY === 66

    if (window.scrollY > 300) {
      // console.log(
      //   `Pasok sa 1st effect ... ScrollY: ${window.scrollY} - height: ${elemHeight} `
      // );
      // console.log(stateRefValue2.current);

      if (stateRefValue2.current?.containerClass === "rca-sticky-inview")
        return;

      setStateValue({
        containerClass: "rca-sticky",
        headerClass: "header-sticky-inview",
      });
      stateRefValue2.current = {
        containerClass: "rca-sticky",
        headerClass: "header-sticky-inview",
      };

      setTimeout(() => {
        setStateValue({
          containerClass: "rca-sticky-inview",
          headerClass: "header-sticky-inview",
        });
        stateRefValue2.current = {
          containerClass: "rca-sticky-inview",
          headerClass: "header-sticky-inview",
        };
      }, 1);
      return;
    }

    // console.log(
    //   `Pasok sa clearing.. ScrollY: ${window.scrollY} - height: ${elemHeight}`
    // );

    setStateValue({ containerClass: "", headerClass: "" });
    stateRefValue2.current = { containerClass: "", headerClass: "" };
  };

  const isFixed = () => {
    return currentMode === NavHeaderTypes.FIXED_MODE;
  };

  // Test
  // useEffect(() => {
  //   debugger;
  //   console.log("Current ScrollY %d", window.scrollY);

  //   document.addEventListener("scroll", testScroll, { passive: true });

  //   return () => {
  //     document.removeEventListener("scroll", testScroll);
  //   };
  // }, []);

  // const testScroll = (e) => {
  //   debugger;
  //   console.log("Current ScrollY %d", window.scrollY);
  // };

  // const handleLogoClick = () => {
  //   debugger;
  //   console.log("Handle Logo Current ScrollY %d", window.scrollY);

  //   setStateValue({
  //     containerClass: "rca-sticky-inview",
  //     headerClass: "header-sticky-inview",
  //   });

  //   setTimeout(() => {
  //     console.log("After logo click Logo Current ScrollY %d", window.scrollY);
  //   }, 50);
  // };

  let mediaSize = getCurrentMediaBreakpoint();

  return (
    <div className="nav-container">
      <div ref={navRef} className={`${stateValue?.containerClass}`}>
        {mediaSize === "desktop" && (
          <header
            className={`position-relative d-flex flex-row justify-content-center ${stateValue?.headerClass}`}
          >
            <a href={brandNavigateUrl} alt="">
              <img src={logoUrl} alt="logo" />
            </a>

            {menuOptionRight && (
              <div className="position-absolute top-0 end-0">
                {menuOptionRight}
              </div>
            )}
          </header>
        )}

        <NavigationBarOffCanvas
          menus={navBarMenus.menus}
          menuAlignment="center"
          showBrand={mediaSize !== "desktop"}
          fixed={null}
          customComponent={offCanvasCustomComponent}
          containerCss="pb-2xx"
        />
      </div>
    </div>
  );
};

NavigationHeader.propTypes = {
  containerCss: PropTypes.string,
  logoUrl: PropTypes.string,
  brandNavigateUrl: PropTypes.string,
  menuOptionRight: PropTypes.object,
  offCanvasCustomComponent: PropTypes.object,
};

NavigationHeader.defaultProps = {
  logoUrl: "/images/logo/logo-site.png",
  brandNavigateUrl: "/",
};

export default NavigationHeader;
