import { useContext, useEffect, useRef } from "react";
import ContentPane from "components/ui/contentPane/ContentPane";
import VideoJS from "components/ui/videoPlayer/VideoPlayer";
import "./page-not-found.scss";
import { NavHeaderContext } from "components/features/navigationHeader/context/NavHeaderContextProvider";
import { NavHeaderTypes } from "constants/navHeaderTypes";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function PageNotFoundView() {
  const playerRef = useRef(null);
  const { changeMode } = useContext(NavHeaderContext);

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    loop: true,
    fluid: true,
    sources: [
      {
        src: "videos/choi-family.mp4",
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    changeMode(NavHeaderTypes.FIXED_MODE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <div className="page-not-found-main-wrapper">
      <RcHelmet
        title="404 - RcnetLabs Life and Code"
        description="RcnetLabs, a coder enthusiast, sharing his experience in latest technology, life and code. A pinoy coder with a great appetite of learning new things, especially in web and cross platform development. Rcnet Labs portfolio site. Blogs and programming tips in fullstack development."
        keywords="Programmer, Programming, Coder, Web developer, Web development, System developer, Cross platform developer, Mobile developer, Fullstack developer, React developer, Expo, React Native developer"
      />
      <ContentPane
        isFullWidth={true}
        hasShadow={false}
        containerCss="page-not-found-wrapper"
      >
        <div className="message">
          <div className="video-wrapper">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          </div>
          <div className="rca-heading1 code">404</div>
          <h1>Page not found</h1>
        </div>
      </ContentPane>
    </div>
  );
}

export default PageNotFoundView;
